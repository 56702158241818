import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../../views/BankDetails/messages';
import SubscriptionIcon from '../../assets/icons/subscription-icon.svg';
import TooltipIcon from '../../assets/icons/subscription-info-icon.svg';
import CheckIcon from '../../assets/icons/subscription-check-icon.svg';

const SubscriptionSidePremiumInfo = () => {
  const { formatMessage } = useIntl();
  const subscriptionStarts = new Date();
  subscriptionStarts.setDate(subscriptionStarts.getDate() + 2);

  return (
    <div className="claim-information-container" style={{ marginTop: '20px' }}>
      <div className="claim-information-content-row">
        <div className="claim-information-icon-container">
          <img src={SubscriptionIcon} width={24} height={24} />
          <span className="claim-information-icon-label">
            {formatMessage(messages.premiumLabel)}
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <span className="claim-information-icon-label">
              {formatMessage(messages.subscriptionPriceLabel)}
            </span>
          </div>
        </div>
      </div>
      <div className="claim-information-content-columns">
        <div className="second-row-premium flex">
          <span>
            {formatMessage(messages.premiumCreditsLabel)}:{' '}
            <strong>{formatMessage(messages.premiumCreditsInfo)}</strong>
          </span>
          <div className="tooltip">
            <img src={TooltipIcon} alt="" style={{ marginLeft: '10px' }} />
            <span className="tooltiptext">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </span>
          </div>
        </div>
      </div>
      <div className="subscription-bank-details-footer-side">
        <div className="subscription-bank-details-footer-content-side">
          <img src={CheckIcon} />
          <span>{formatMessage(messages.subscriptionCompenstationLabel)}</span>
        </div>
        <div className="subscription-bank-details-footer-content-side">
          <img src={CheckIcon} />
          <span>{formatMessage(messages.subscriptionCommissionLabel)}</span>
        </div>
        <div className="subscription-bank-details-footer-content-side">
          <img src={CheckIcon} />
          <span>{formatMessage(messages.subscriptionCardInfoLabel)}</span>
        </div>
      </div>
      <div className="subscription-bank-details-footer-side-text">
        <span>
          {formatMessage(messages.subscriptionSideFooterText)}{' '}
          {subscriptionStarts.toLocaleDateString()}
        </span>
      </div>
    </div>
  );
};

export default SubscriptionSidePremiumInfo;
